.modal-content-wrapper-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  height: 80vh;
  margin-left: -15%;
}

.modal-content-wrapper-1 {
  margin: auto;
  display: flex;
  flex-direction: row;
  margin-top: 10vh;
  margin-left: 23.5%;
  outline: none;
}

.modal-content-wrapper-3 {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.modal-main-heading {
  writing-mode: tb-rl;
  text-orientation: inherit;
  transform: rotate(180deg);
  text-align: -webkit-left;
  font-family: TungstenFont;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 60px;
  /* or 69% */
  margin-right: 30px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: "sinf" on;
  color: #000000;
  opacity: 0.9;
  margin-left: 0px;
  margin-right: 8px;
}

.modal-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65vw;
  height: 88vh;
  background: #fff;
  padding-top: -5px;
  padding: 2%;
  border: 10px solid #f0f0f0;
}

.close-button {
  cursor: pointer;
  margin-left: 2%;
}

.heading-text {
  font-family: BoldFont;
  color: #003726;
  margin-top: 0px;
  font-size: 18px;
  text-align: left;
}

.description-text {
  font-family: RegularFont;
  color: #000000;
  margin-top: -10px;
  font-size: 15px;
  text-align: left;
}
