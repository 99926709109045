* {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: row;
  width: calc(12.8vw * 20);
  gap: 25px;
  /* animation: scroll 40s linear infinite;   */
}

.sliderGallery {
  overflow: hidden;
  width: auto;
  height: 80vh;
  margin-top: 4vh;
}

.main {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  margin-top: 4vh;
}

.slides-container {
  position: relative;
  overflow: hidden;
  height: 80%;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
}

.Col {
  margin-left: 0.8vw;
}

.Col1 {
  margin-top: 0px;
}

.Col2 {
  margin-top: 4%;
}

.Col3 {
  margin-top: 6%;
}

.card {
  display: block;
  border: 6px solid #67a59f;
  background: white;
  width: 12vw;
  height: 35vh;
  cursor: pointer;
}

.InnerImgCard {
  width: 100%;
  height: 100%;
  margin: auto;
}

/* .slide {
  position: absolute;
  font-size: 90px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: calc(100% / 3);
  width: 100%;
}  */

.slides-inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.CardHeadingEnvironment {
  font-family: BoldFont;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  /* or 156% */
  margin-top: 0px;
  text-transform: uppercase;
  color: rgba(0, 23, 56, 0.61);
}
