.PageBackground {
  text-align: center;
  min-height: 100vh;
  background-image: url("ESG_assets/images/BG.png");
}

.landScapeMode {
  display: none;
}

@media only screen and (orientation: portrait) {
  .landScapeMode {
    display: block;
    transform: rotate(90deg);
  }

  .PageBackground {
    display: none;
  }
  .Environment {
    display: none;
  }
  .Governance {
    display: none;
  }
  .Social {
    display: none;
  }
  .Journey {
    display: none;
  }
}

.Environment {
  text-align: center;
  background-image: url("Images_4/06_ENV_Gallery_bg.jpg");
}

.Governance {
  text-align: center;
  background-image: url("Images_4/015_Governance_Gallery__Zoom_bg.jpg");
}
.Social {
  text-align: center;
  background-image: url("Images_4/10_Social_Gallery_bg.jpg");
}

.Journey {
  text-align: center;
  background-image: url("Images_4/016_ESG_Journey_bg.jpg");
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  min-height: 16vh;
  min-width: 90%;
  display: grid;
  /* font-size: calc(10px + 2vmin); */
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
