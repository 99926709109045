* {
  box-sizing: border-box;
}



.container {
  display: flex;
  flex-direction: row;
  width: calc(12.7vw*16);
  gap: 25px;
  /* animation: scroll 40s linear infinite;   */
 }

.sliderGallery {
  overflow: hidden;
  width: auto;
  height: 80vh;
  margin-top: 4vh
}


.main {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  margin-top: 4vh;
}

.slides-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
  height: 80%;
  display: flex;
  flex-direction: row;
}



.Social-Col {
  margin-left:0.7vw; 
}  

 .Col1 {
  margin-top: 0px;
}

.Col2 {
  margin-top: 4%;
}

.Col3 {
  margin-top: 6%;
} 
.socialcard {
  display: block;
  border: 7px solid #129ee4;
  background: white;
  cursor: pointer;
  width: 12vw;
  height:  35vh;
}

/* .slide {
    position: absolute;
    font-size: 90px;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: calc(100% / 3);
    width: 100%;
  }  */

.slides-inner {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.CardHeadingSocial{
  font-family: BoldFont;
font-weight: bold;
font-size: 14px;
line-height: 18px;
/* or 156% */
text-align: left;
text-transform: uppercase;
margin-top: 0px;
color: rgba(0, 23, 56, 0.61);

}