body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: RegularFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-REGULAR.OTF");
}

@font-face {
  font-family: SemiBoldFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-SEMIBOLD.OTF");
}

@font-face {
  font-family: BoldFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-BOLD.OTF");
  font-weight: bolder;
}

@font-face {
  font-family: BoldCond;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-BOLDCOND.OTF");
  font-weight: bolder;
}
@font-face {
  font-family: BoldCondITFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-BOLDCONDIT.OTF");
}
@font-face {
  font-family: CondFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-COND.OTF");
}


@font-face {
  font-family: TungstenFont;
  font-weight: bolder;
  src: url("ESG_assets/Guidelines/fonts/tungsten-medium.otf");
}

@font-face {
  font-family: TungstenTTFFont;
  src: url("ESG_assets/Guidelines/fonts/Tungsten-Medium.ttf");
}
@font-face {
  font-family: SemiBoldITFont;
  src: url("ESG_assets/Guidelines/fonts/MYRIADPRO-SEMIBOLDIT.OTF");
}

@font-face {
  font-family: LightFont;
  src: url("ESG_assets/Guidelines/fonts/MyriadPro-Light.otf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
