body {
  margin: 0;
}
.sea {
  background-color: #50af91;
  position: fixed;
  top: 100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  /*animation: sea 10s ease-in-out;
      animation-direction: alternate;
      animation-iteration-count: infinite*/
}

svg {
  position: absolute;
  width: 100%;
  height: 130%;
  bottom: 100%;
  left: 0%;
}

.water-fill {
  -webkit-animation: wave 0.8s infinite linear,
    fill-up 2s infinite ease-in-out alternate;
  animation: wave 0.8s infinite linear,
    fill-up 2s infinite ease-in-out alternate;
}

@keyframes wave {
  0% {
    x: -750px;
  }
  100% {
    x: 0;
  }
}
@-webkit-keyframes wave {
  0% {
    x: -750px;
  }
  100% {
    x: 0;
  }
}
@keyframes fill-up {
  0% {
    height: 0;
    y: 100%;
  }
  100% {
    height: 100%;
    y: 0%;
  }
}
@-webkit-keyframes fill-up {
  0% {
    height: 0;
    y: 100%;
  }
  100% {
    height: 100%;
    y: 0%;
  }
}
