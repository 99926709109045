.footer-text {
  writing-mode: tb-rl;
  text-orientation: inherit;
  transform: rotate(180deg);
  margin: auto;
  color:#000000;;
  
}
.copy {
  font-size: 9px;
  letter-spacing: 1px;
  font-family: RegularFont;
  font-weight: 600;
  line-height: 6px;
  writing-mode: vertical-rl;
  text-orientation: sideways-right;
  transform: rotate(180deg);
}
.footer-img {
  transform: rotate(90deg);
  width: 50px;
  margin-top: 36px;
}

.footer-social-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
