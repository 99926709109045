.carousel-status {
  display: none;
}

.carousel .thumbs-wrapper {
  display: none;
}

.control-dots {
  display: none;
}

.parent-of-carousal {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 0.5%;
  padding-bottom: 1.3%;
}

.parent-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 80vh;
  max-width: 100%;
}

.parent-comp-1 {
  display: flex;
  flex-direction: row;
  max-width: 64%;
}

.parent-comp-2 {
  display: flex;
  flex-direction: row;
  max-width: 68.5%;
}

.social-main-heading {
  writing-mode: tb-rl;
  text-orientation: inherit;
  transform: rotate(180deg);
  text-align: -webkit-left;
  font-family: TungstenFont;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 60px;
  /* or 69% */
  margin-right: 30px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: "sinf" on;
  color: #001738;
  opacity: 0.9;
  margin-left: 0px;
  margin-right: 8px;
  /* transform: rotate(-90deg); */
}

.social-main-card {
  border: 8px solid #027fbd;

  height: 79vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #eff5f0;
}

.upper-content-div-1 {
  padding-top: 8%;
  padding-left: 3%;
  padding-right: 3%;
}

.upper-content-div-2 {
  padding-top: 10%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}

.social-main-content {
  font-family: BoldFont;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  /* or 100% */
  margin-top: 0px;
  margin-bottom: 0px;
  background: #eff5f0;
  text-transform: uppercase;
  color: #112a4c;
}

.social-para-style {
  font-family: TungstenFont;
  font-weight: bold;
  color: #212125;
  text-align: start;
}

.total-no-style {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 1.5em;
}

.card-side-bar {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}

.cardno-style {
  font-size: 163px;
  margin-bottom: 0px;
  margin-top: -40px;
}

.social-card-type {
  font-family: BoldFont;
  font-size: 12px;
  line-height: 25px;
  color: #212125;
}

.close-button-box {
  cursor: pointer;
}
.close-button {
  width: 60% !important;
  float: left;
}

@media screen and (max-width: 700px) {
  .parent-wrapper {
    min-height: 430px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 6em;
  }
  .social-main-heading {
    font-size: 3em;
  }
}

@media screen and (max-width: 570px) {
  .parent-wrapper {
    min-height: 380px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 5em;
  }
  .social-main-heading {
    font-size: 2.4em;
  }
  .social-main-content {
    font-size: 13px;
  }
  .close-button {
    width: 60%;
  }
}

@media screen and (max-width: 480px) {
  .parent-wrapper {
    min-height: 330px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 4em;
    margin-top: -15px;
  }
  .social-main-heading {
    font-size: 2em;
  }
  .social-main-content {
    font-size: 11px;
  }
  .social-card-type {
    font-size: 16px;
  }
  .close-button {
    width: 60%;
  }
}

@media screen and (max-width: 352px) {
  .parent-wrapper {
    min-height: 270px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 3em;
    margin-top: -15px;
  }
  .social-main-heading {
    font-size: 1.6em;
  }
  .social-main-content {
    font-size: 9px;
  }
  .social-card-type {
    font-size: 16px;
  }
  .close-button {
    width: 60%;
  }
}
