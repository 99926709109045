.carousel-status {
  display: none;
}

.carousel .thumbs-wrapper {
  display: none;
}

.control-dots {
  display: none;
}

.parent-of-carousal {
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 0.5%;
  padding-bottom: 1.3%;
}

.parent-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 80vh;
  justify-content: center;
}

.parent-comp-1 {
  display: flex;
  flex-direction: row;
  max-width: 64%;
}

.parent-comp-2 {
  display: flex;
  flex-direction: row;
  max-width: 68.5%;
}

.gov-main-heading {
  writing-mode: tb-rl;
  text-orientation: inherit;
  transform: rotate(180deg);
  text-align: -webkit-left;
  font-family: TungstenFont;
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 60px;
  /* or 69% */
  margin-right: 30px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-feature-settings: "sinf" on;
  opacity: 0.9;
  color: #553676;
  text-align: center;
  margin-left: 0px;
  margin-right: 8px;
}

.gov-main-card {
  border: 8px solid #8d2a90;
  background-color: #eff5f0;
  height: 79vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.upper-content-div {
  padding-top: 10%;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 2%;
}

.gov-main-content {
  font-family: BoldFont;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  line-height: 18px;
  /* or 100% */
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  text-transform: uppercase;
  color: #614480;
}

.gov-para-style {
  font-family: TungstenFont;
  font-weight: bold;
  color: #381460;
}

.total-no-style {
  margin-bottom: 0px;
  font-size: 1.5em;
  margin-top: 0px;
}

.card-side-bar {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}

.cardno-style {
  font-size: 163px;
  margin-bottom: 0px;
  margin-top: -40px;
}

.right-info {
  margin-top: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gov-card-type {
  font-family: BoldFont;
  font-size: 12px;
  line-height: 25px;
  color: #381460;
}

.close-button-box {
  cursor: pointer;
}

.close-button {
  width: 60% !important;
}

@media screen and (max-width: 700px) {
  .parent-wrapper {
    min-height: 430px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 6em;
  }
  .gov-main-heading {
    font-size: 3em;
  }
}

@media screen and (max-width: 570px) {
  .parent-wrapper {
    min-height: 380px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 5em;
  }
  .gov-main-heading {
    font-size: 2.4em;
  }
  .gov-main-content {
    font-size: 13px;
  }
  .close-button {
    width: 60% !important;
  }
}

@media screen and (max-width: 480px) {
  .parent-wrapper {
    min-height: 330px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 4em;
    margin-top: -15px;
  }
  .gov-main-heading {
    font-size: 2em;
  }
  .gov-main-content {
    font-size: 11px;
  }
  .gov-card-type {
    font-size: 16px;
  }
  .close-button {
    width: 60% !important;
  }
}

@media screen and (max-width: 352px) {
  .parent-wrapper {
    min-height: 270px;
    margin-top: 8%;
  }
  .cardno-style {
    font-size: 3em;
    margin-top: -15px;
  }
  .gov-main-heading {
    font-size: 1.6em;
  }
  .gov-main-content {
    font-size: 9px;
  }
  .gov-card-type {
    font-size: 16px;
  }
  .close-button {
    width: 60% !important;
  }
}
