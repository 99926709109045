.sidebar-style {
  height: 40vh;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
}
.sidebar-text {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
